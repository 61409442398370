<template>
  <div>
    <DataTableComponent :table="table"></DataTableComponent>
  </div>
</template>

<script>
import DataTableComponent from "../../components/DataTableComponent";
export default {
  data() {
    return {
      table: {
        modal: "Partner",
        path: "partners",
        headers: [
          {
            text: this.$i18n.t("Name"),
            value: "partner_name",
            class: "nameField",
          },
          {
            text: this.$i18n.t("Code"),
            value: "code",
          },
          {
            text: this.$i18n.t("Partner Group"),
            value: "group",
          },
          {
            text: this.$i18n.t("Type"),
            value: "type",
          },
          {
            text: this.$i18n.t("Mobile"),
            value: "mobile",
            sortable: false,
          },
          {
            text: this.$i18n.t("Email"),
            value: "email",
            sortable: false,
          },
          {
            text: this.$i18n.t("Notes"),
            value: "notes",
            sortable: false,
          },
          {
            text: this.$i18n.t("Actions"),
            value: "actions",
            sortable: false,
            width: "12%",
          },
        ],
        title: this.$i18n.t("Partners"),
        apiEndPoints: {
          list: "partners",
          create: "partners/store",
          edit: "partners/update",
          delete: "partners/destroy",
        },
        editedItem: {
          name: "",
          group_id: "",
          type: "",
          country_code_id: 187,
          mobile: "",
          email: "",
          notes: "",
          tax_registration: "",
        },
        permissions: {
          list: "list-partners",
          add: "add-partners",
          edit: "edit-partners",
          delete: "delete-partners",
        },
        filter: {
          type: [],
          group_ids: [],
        },
      },
    };
  },
  methods: {},
  components: {
    DataTableComponent,
  },
  mounted() {},
};
</script>

<style></style>
